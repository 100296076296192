import React from "react"
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  Flex,
} from "@chakra-ui/react"
import { graphql } from "gatsby"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons"

import {
  IntroText,
  PrimaryPagesHero,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"
import {
  Container,
  Layout,
  Main,
  Section,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"
import { Seo } from "../../../components/elements"

const Accreditations = ({ data, pageContext }) => {
  const page = data.datoCmsCancerAccreditation
  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <Layout>
      <Seo
        title={page.seo.title}
        description={page.seo.description}
        image={page.seo.image && page.seo.image.url}
      />

      <PrimaryPagesHero page={page}>
        <Breadcrumb
          crumbs={crumbs}
          crumbSeparator=" / "
          crumbLabel={page.title}
        />
      </PrimaryPagesHero>

      <Main>
        <Section>
          <Container sx={{ px: "1em" }}>
            <Flex flexWrap="wrap" justifyContent="center">
              <IntroText width="full">{page.introContent}</IntroText>
              <Box width={["full", "full", 3 / 4]}>
                {page?.accreditations?.length > 0 ? (
                  <>
                    <h2>Accreditations</h2>
                    <Accordion width="full" allowMultiple>
                      {page.accreditations.map(({ ...item }) => (
                        <AccordionItem key={item.id}>
                          <AccordionButton>
                            <Box as="h3" flex="1" textAlign="left" mb={0}>
                              {item.title}
                            </Box>
                            <FontAwesomeIcon icon={faChevronDown} />
                          </AccordionButton>

                          <AccordionPanel pt={6}>
                            <Box
                              sx={{ width: ["full"] }}
                              dangerouslySetInnerHTML={{
                                __html:
                                  item.descriptionNode.childMarkdownRemark.html,
                              }}
                            />
                          </AccordionPanel>
                        </AccordionItem>
                      ))}
                    </Accordion>
                  </>
                ) : null}

                {page?.accreditations?.length > 0 ? (
                  <Box mt={[6, 6, 9]}>
                    <h2>Designations</h2>
                    <Accordion width="full" allowMultiple>
                      {page.designations.map(({ ...item }) => (
                        <AccordionItem key={item.id}>
                          <AccordionButton>
                            <Box as="h3" flex="1" textAlign="left" mb={0}>
                              {item.title}
                            </Box>
                            <FontAwesomeIcon icon={faChevronDown} />
                          </AccordionButton>

                          <AccordionPanel pt={6}>
                            <Box
                              sx={{ width: ["full"] }}
                              dangerouslySetInnerHTML={{
                                __html:
                                  item.descriptionNode.childMarkdownRemark.html,
                              }}
                            />
                          </AccordionPanel>
                        </AccordionItem>
                      ))}
                    </Accordion>
                  </Box>
                ) : null}
              </Box>
            </Flex>
          </Container>
        </Section>
      </Main>
    </Layout>
  )
}

export default Accreditations

export const query = graphql`
  {
    datoCmsCancerAccreditation {
      title
      slug
      introContent
      heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { ar: "1600:720", fit: "crop", crop: "focalpoint" }
        )
      }
      accreditations {
        id
        title
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
      }
      designations {
        id
        title
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
      }
      seo {
        description
        title
        image {
          url
        }
      }
    }
  }
`
